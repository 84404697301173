<template>
    <div :key="upload_key">
        <LiefengUpload ref="liefeng_upload" target="1" :multiple="multiple" :fileLength="multiple ? 9 : 1" :accept="accept" :format="format" :defaultList="val" :showView="showView" @success="onSuccessUpload"></LiefengUpload>
    </div>
</template>

<script>
import LiefengUpload from "@/components/LiefengUpload.vue"

export default {
    components: {
        LiefengUpload,
    },

    data() {
        return {
            val: [],
            upload_key: this.$core.randomString(),
            display: true,
        }
    },

    props: {
        name: {
            type: String,
            required: false,
        },
        accept: {
            type: String,
            default: ".jpg,.png,.gif,.jpeg",
        },
        format: {
            type: Array,
            default: ["jpg", "png", "jpeg", "gif"],
        },
        showView: {
            type: Boolean,
            default: true,
        },
        value: {
            type: [String, Array],
            required: false,
        },
        // 多文件模式
        multiple: {
            type: Boolean,
            default: false,
        },
        // 简洁模式，仅返回 url
        concise: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        value: {
            handler(val) {
                if (val && val !== this.val) {
                    if (val && "string" === typeof val) {
                        this.val = [{ name: "", url: val }]
                    } else if ("object" === typeof val) {
                        val = JSON.parse(JSON.stringify(val))

                        if (val instanceof Array) {
                            for (let i = 0; i < val.length; i++) {
                                const e = val[i]

                                if ("string" === typeof e) {
                                    val[i] = { name: "", url: e }
                                }
                            }

                            this.val = val
                        } else {
                            this.val = [val]
                        }
                    }

                    this.upload_key = this.$core.randomString()
                } else if (!val) {
                    // 置空
                    this.empty()
                }
            },
            immediate: true,
        },
    },

    methods: {
        /**
         * 置空
         */
        empty() {
            this.val = []

            if (this.$refs.liefeng_upload) {
                this.$refs.liefeng_upload.uploadList = []
            }

            this.upload_key = this.$core.randomString()
        },

        /**
         * 监听上传成功事件
         */
        onSuccessUpload() {
            const list = this.$refs.liefeng_upload.uploadList

            var res = {
                name: this.name,
                type: "elem-upload",
            }

            if (list && list.length > 0) {
                // 是否为多文件模式
                if (this.multiple) {
                    var val

                    if (this.concise) {
                        val = []

                        for (let i = 0; i < list.length; i++) {
                            val.push(list[i].url)
                        }
                    } else {
                        val = list
                    }

                    res.value = val
                } else {
                    res.value = this.concise ? list[0].url : list[0]
                }
            }

            this.$emit("on-change", res)
        },
    },
}
</script>

<style></style>
